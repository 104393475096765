import React, { Component, useEffect, useState } from "react";
import { Redirect, HashRouter, Route, Switch } from "react-router-dom";
import { locale } from "./locales";
import { valorAD } from "./index"
import "./Style.scss";

const AppLayout = React.lazy(() => import("./layout/AppLayout"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const LoginAD = React.lazy(() => import("./pages/Login/LoginAD"));
const AlterarSenha = React.lazy(() => import("./pages/Login/AlterarSenha"));
const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;

function App() {

  const isAuthenticated = () =>
  localStorage.getItem("token") !== null

  setInterval(() => {
    const elements = document.getElementsByClassName("apexcharts-menu-icon");

    [...elements].forEach((element) =>
      element.addEventListener("mouseover", () => element.setAttribute("title", "Imagem"))
    );
  }, 1000);

  if (valorAD === 1) {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/loginAD" name="LoginAD" render={(props) => <LoginAD {...props} />} />

            <Route
              render={(props) =>
                isAuthenticated() ? (
                  <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
                ) : (<Redirect to="/loginAD"></Redirect>)
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  } else {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />

            <Route
              path="/primeiroLogin"
              name="interativit"
              render={(props) =>
                isAuthenticated() ? (
                  <AlterarSenha {...{ ...props, firstLogin: true }} />
                ) : (
                  <Redirect to="/login"></Redirect>
                )
              }
            />

            <Route
              render={(props) =>
                isAuthenticated() ? (
                  <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
                ) : (<Redirect to="/login"></Redirect>)
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}


export default App;
